<template>
  <div style="overflow-y:hidden;">
    <div :class="dh1==true ? 'hidecaidancss':'caidancss' ">
      <div style="position: fixed;left: 43%; top: 50%;text-align: center;color: #ffffff;">
        <a class="xhxa">
          <router-link to="/">首页</router-link>
        </a>
        <a class="xhxa">
          <router-link to="/about">关于小橘</router-link>
        </a>
        <a class="xhxa">
          <router-link to="/download">下载</router-link>
        </a>
        

      </div>
    </div>

    <div class="miniheaderabout" >
      <div style="display: flex;align-items: center; z-index: 99999999;">
        <img src="../image/LOGO.png" style="height: 64.8px;width: 112.9px; ">
        <div @click="asd" style="margin-left: auto;margin-right: 10px;">
          <div :class="dh1==true ? 'caidan3fixed':'caidan3' ">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div :class="dh1==true ? '':'caidanx1' "></div>
            <div :class="dh1==true ? '':'caidanx2' "></div>
          </div>
        </div>
      </div>
    </div>




    <div class="header">
      <div>
        <div class="miniblank"></div>
        <img src="https://hongqi1.oss-cn-zhangjiakou.aliyuncs.com/contact.jpg?versionId=CAEQHxiBgMCtya.o7BciIDYzMTQ2YmJhZThmOTQ0Y2RiMzhmMWJhYzEwOGU0Mzcx" class="bigimg">
        <div class="bannera">
          <div class="banner1  wow fadeInLeft "  data-wow-duration="2s" data-wow-delay="0.1s">Contact orange</div>
          <div class="banner2 wow fadeInLeft "  data-wow-duration="2s" data-wow-delay="0.3s" >『极致风景处 就住橘子宿』</div>
        </div>
      </div>
      <div class="bigheader" style="position: absolute;right: 0;top: 20px;">
        <ul class="navigation ">
          <li class="xhxa">
            <router-link to="/">首页</router-link>
          </li>
          <li class="xhxa">
            <router-link to="/about">关于橘子</router-link>
          </li>
          <li class="xhxa">
            <router-link to="/download">下载</router-link>
          </li>
          <li class="xhxa">
            <router-link to="/contact">联系小橘</router-link>
          </li>
        </ul>
      </div>
    </div>



    <div class="foot">
      <div><img src="../image/LOGO.png" style="width: 376px;height: 216px;"></div>
      <div>
        <h2>联系方式</h2>
        <div>E-mail：1326058321@qq.com</div>
        <div>电话：13255987620</div>
        <div>地址：福建省厦门市</div>
        <div>小程序超出个人开发者服务范围，后续更新</div>
        <div>需要企业号认证，完整功能推荐使用app</div>
      </div>
      <div style="display: flex;">
        <div style="margin-right: 50px;">
          <div style="text-align: center;margin-bottom: 10px;">微信小程序</div>
          <img src="../image/wei.png" style="width: 100px;height: 100px;">
        </div>
        <div>
          <div style="text-align: center;margin-bottom: 10px;">app下载</div>
          <img src="../image/app123.png" style="width: 100px;height: 100px;">
        </div>
      </div>
    </div>
    <div style="text-align: center;background-color: #EEEEEE; ">备案号：<a target="_blank"
        href="https://beian.miit.gov.cn">闽ICP备2020019044号</a>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        dh1: true,
      }
    },
    mounted() {
      // 在项目加载完成之后初始化wow    
      this.$nextTick(() => {
        new this.$wow().init()
      })
    },
    methods: {
      asd() {
        this.dh1 = !this.dh1
        this.caidan3flag = !this.caidan3flag
        console.log('233', this.caidan3flag);
      }
    },
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  .miniblank {
    height: 0px;
  }

  /* 解决routerlink 样式问题 */
  .router-link-active {
    text-decoration: none;
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
  }


  .caidanx1 {
    width: 30px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    transform: rotate(45deg);
    animation-name: caidanxEnter;
    animation-duration: 1s;
    animation-direction: reverse;
  }

  .caidanx2 {
    width: 30px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    transform: rotate(-45deg);
    animation-name: caidanxEnter;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
  }

  @keyframes caidanxEnter {
    100% {
      transform: translateX(50px);
    }
  }


  .caidan3fixed {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    margin-top: 10px;
  }

  .caidan3fixed div {
    width: 25px;
    height: 4px;
    margin-bottom: 4px;
    background-color: rgb(0, 0, 0);
  }


  .caidan3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    margin-top: 10px;
  }

  .caidan3 div {
    width: 25px;
    height: 4px;
    margin-bottom: 4px;
    background-color: rgb(0, 0, 0);
  }

  .caidan3 :nth-child(1) {
    animation-name: caidan3move;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .caidan3 :nth-child(2) {
    animation-name: caidan3move;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .caidan3 :nth-child(3) {
    animation-name: caidan3move;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  @keyframes caidan3move {
    100% {
      transform: translateX(20vh);
    }
  }




  .xhxa {
    display: block;
    position: relative;
    margin-bottom: 25px;
    list-style: none;
  }

  .xhxa:hover:before {
    content: "";
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -2px;
    background: #ff961a;
    animation-name: xhx;
    animation-duration: 1s;
  }





  @keyframes xhx {
    from {
      width: 0;

    }

    to {
      width: 100%;
    }
  }

  .hidecaidancss {
    height: 0px;
    display: none;
  }

  .caidancss {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 65px;
    width: 100%;
    height: 2000px;

    /* transform: translateX(-50vh); */
    background-color: #D4CBC6;
    animation-name: hd;
    animation-duration: 1s;
  }

  @keyframes hd {
    0% {
      transform: translateX(-100vh);
    }

    100% {
      transform: translateX(0px);
    }
  }

  .bigimg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .foot {
    height: 300px;
    width: 100%;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }



  .bannera {
    color: white;
    left: 20px;
    right: 0;
    top: 100px;
    white-space: nowrap;
    position: absolute;
    z-index: 999;
  }
  .banner1{
    font-size: 2rem;
  }

  .miniheaderabout {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 65px !important;
    z-index: 9999;
    background-color: #fff;
    visibility: hidden;
  }

  .header .navigation {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  .header .navigation li {
    list-style: none;
    margin: 0 20px;
  }

  .header .navigation li a {
    text-decoration: none;
    color: #fff;
  }



  /*屏幕宽度小于991px,改变布局和样式*/
  @media screen and (max-width:991px) {
    .bigheader {
      visibility: hidden;
    }

    .miniblank {
      height: 65px;
    }

    .miniheaderabout {
      height: 50px;
      align-items: center;
      visibility: visible;
    }

    .bannera {
      margin: 0 auto;
      width: 269.5px;
      height: 87px;
      position: absolute;
      z-index: 999;
    }


    .foot {
      height: 700px;
      width: 100%;
      background-color: #EEEEEE;
      display: flex;
      flex-direction: column;
    }

  }
</style>