<template>
  <div style="overflow-y:hidden;">
    <div :class="dh1==true ? 'hidecaidancss':'caidancss' ">
      <div style="position: fixed;left: 43%; top: 50%;text-align: center;color: #ffffff;">
        <a class="xhxa">
          <router-link to="/about">关于橘子</router-link>
        </a>
        <a class="xhxa">
          <router-link to="/download">下载</router-link>
        </a>
        <a class="xhxa">
          <router-link to="/contact">联系小橘</router-link>
        </a>
      </div>
    </div>

    <div class="miniheader" style="height: 94px;">
      <div style="display: flex;align-items: center; z-index: 99999999;">
        <img src="../image/LOGO.png" style="height: 64.8px;width: 112.9px; ">
        <div @click="asd" style="margin-left: auto;margin-right: 10px;">
          <div :class="dh1==true ? 'caidan3fixed':'caidan3' ">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div :class="dh1==true ? '':'caidanx1' "></div>
            <div :class="dh1==true ? '':'caidanx2' "></div>
          </div>
        </div>
      </div>
      <div style="display: flex;">
        <a class="btn" style="flex: 1;">
          <router-link to="/download">DOWNLOAD NOW</router-link>
        </a>
      </div>
    </div>



    <div class="header">
      <div >
        <div>
          <div class="miniblank"></div>
          <video autoplay="" playsinline="" loop="" muted=""
            style="min-height: 100%;min-width: 100%;position: relative">
            <source src="https://hongqi1.oss-cn-zhangjiakou.aliyuncs.com/webshipin.mp4?versionId=CAEQHxiBgICb85Oo7BciIDkyMjQwMDBiY2FhNzQ1Y2NhZTNhNTRjNGM5YzQzODli" type="video/mp4">
          </video>
          <img src="../image/img2.png" class="jzimg">

        </div>
        <div class="bigheader" style="position: absolute;right: 0;top: 20px;">
          <ul class="navigation ">
            <li class="xhxa">
              <router-link to="/">首页</router-link>
            </li>
            <li class="xhxa">
              <router-link to="/about">关于橘子</router-link>
            </li>
            <li class="xhxa">
              <router-link to="/download">下载</router-link>
            </li>
            <li class="xhxa">
              <router-link to="/contact">联系小橘</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>





    <div style="height: 50px;"></div>
    <div style="text-align: center;">

      <p style="font-size:30px" class="wow fadeInLeft" >关于橘子</p>
      <!-- <p  class="wow fadeInLeft" data-wow-duration="1s"></p> -->
      <div style="height: 20px;"></div>
      <div class="aboutOrange">
        <img src="https://hongqi1.oss-cn-zhangjiakou.aliyuncs.com/xiamen.jpg?versionId=CAEQHxiBgMDjyq.o7BciIDk1NjRlZmEyMGQ2MDRhNDFhYWNjZWFjYWNjOTFmNWVh"  class="wow fadeInLeft" data-wow-duration="2s">
        <p style="color:#666666;margin-top: 10px;" class="wow fadeInRight" data-wow-duration="2s">
          &emsp;&emsp;个人项目，基于vue、nodejs、express、elementUI、mongodb数据库、uniapp等
        </p>
      </div>
    </div>

    <div style="height: 50px;"></div>
   

    <div class="foot">
      <div><img src="../image/LOGO.png" style="width: 376px;height: 216px;"></div>
      <div>
        <h2>联系方式</h2>
        <div>E-mail：1326058321@qq.com</div>
        <div>电话：13255987620</div>
        <div>地址：福建省厦门市</div>
        <div>小程序超出个人开发者服务范围，后续更新</div>
        <div>需要企业号认证，完整功能推荐使用app</div>
      </div>
      <div style="display: flex;">
        <div style="margin-right: 50px;">
          <div style="text-align: center;margin-bottom: 10px;">微信小程序</div>
          <img src="../image/wei.png" style="width: 100px;height: 100px;">
        </div>
        <div>
          <div style="text-align: center;margin-bottom: 10px;">app下载</div>
          <img src="../image/app123.png" style="width: 100px;height: 100px;">
        </div>
      </div>
    </div>
    <div style="text-align: center;background-color: #EEEEEE; ">备案号：<a target="_blank"
        href="https://beian.miit.gov.cn">闽ICP备2020019044号</a>
    </div>

  </div>
</template>

<script >
  export default {
    data() {
      return {
        dh1: true,
      }
    },
    mounted() {
      // 在项目加载完成之后初始化wow    
      this.$nextTick(() => {
        new this.$wow().init()
      })
    },
    methods: {
      asd() {
        this.dh1 = !this.dh1
        this.caidan3flag = !this.caidan3flag
        console.log('233', this.caidan3flag);
      }
    },
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
  

  .miniblank {
    height: 0px;
  }

  /* 解决routerlink 样式问题 */
  .router-link-active {
    text-decoration: none;
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
  }

  .btn {
    height: 30px;
    width: 100%;
    text-align: center;
    color: white;
    line-height: 2;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 51%, #ffecd2 100%);
  }

  .btn:hover {
    background-position: right center
  }




  .caidanx1 {
    width: 30px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    transform: rotate(45deg);
    transform-origin:center center;
    animation-name: caidanxEnter;
    animation-duration: 1s;
    animation-direction: reverse;
  }

  .caidanx2 {
    width: 30px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    transform: rotate(-45deg);
    transform-origin: center center;
    animation-name: caidanxEnter;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
  }

  @keyframes caidanxEnter {
    100% {
      transform: translateX(50px);
    }
  }


  .caidan3fixed {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    margin-top: 10px;
  }

  .caidan3fixed div {
    width: 25px;
    height: 4px;
    margin-bottom: 4px;
    background-color: rgb(0, 0, 0);
  }


  .caidan3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    margin-top: 10px;
  }

  .caidan3 div {
    width: 25px;
    height: 4px;
    margin-bottom: 4px;
    background-color: rgb(0, 0, 0);
  }

  .caidan3 :nth-child(1) {
    animation-name: caidan3move;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .caidan3 :nth-child(2) {
    animation-name: caidan3move;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .caidan3 :nth-child(3) {
    animation-name: caidan3move;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  @keyframes caidan3move {
    100% {
      transform: translateX(20vh);
    }
  }



  .xhxa {
    display: block;
    position: relative;
    margin-bottom: 25px;
    list-style: none;
  }

  .xhxa:hover:before {
    content: "";
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -2px;
    background: #ff961a;
    animation-name: xhx;
    animation-duration: 1s;
  }





  @keyframes xhx {
    from {
      width: 0;

    }

    to {
      width: 100%;
    }
  }

  .hidecaidancss {
    height: 0px;
    display: none;
  }

  .caidancss {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 94px;
    width: 100%;
    height: 2000px;

    /* transform: translateX(-50vh); */
    background-color: #D4CBC6;
    animation-name: hd;
    animation-duration: 1s;
  }

  @keyframes hd {
    0% {
      transform: translateX(-100vh);
    }

    100% {
      transform: translateX(0px);
    }
  }

  video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .foot {
    height: 300px;
    width: 100%;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .aboutOrange {
    display: flex;
    margin-left: 50px;
    margin-right: 50px;
    /* flex-direction: column; */
  }

  .aboutOrange img {
    width: 684px;
    height: 456px;
    margin-right: 20px;
  }

  .jzimg {
    left: 20px;
    right: 0;
    top: 100px;
    width: 539px;
    height: 174px;
    position: absolute;
    z-index: 999;
  }

  .miniheader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 94px !important;
    z-index: 9999;
    background-color: #fff;
    visibility: hidden;
  }

  .header .navigation {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  .header .navigation li {
    list-style: none;
    margin: 0 20px;
  }

  .header .navigation li a {
    text-decoration: none;
    color: #fff;
  }



  /*屏幕宽度小于991px,改变布局和样式*/
  @media screen and (max-width:991px) {
    .bigheader {
      visibility: hidden;
    }

    .miniblank {
      height: 94px;
    }

    .miniheader {
      height: 94px;
      align-items: center;
      visibility: visible;
    }

    .jzimg {
      margin: 0 auto;
      width: 269.5px;
      height: 87px;
      position: absolute;
      z-index: 999;
    }

    .aboutOrange {
      display: flex;
      flex-direction: column;
    }

    .aboutOrange img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .foot {
      height: 700px;
      width: 100%;
      background-color: #EEEEEE;
      display: flex;
      flex-direction: column;
    }

  }
</style>