import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false


import {WOW} from 'wowjs'
import '../node_modules/wowjs/css/libs/animate.css'
Vue.prototype.$wow=WOW;

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
